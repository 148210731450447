<template>
  <div>
    <b-modal
      id="add-new-feed-sidebar"
      centered
      :visible="isAddNewFeedSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('addFeed')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankFeedData)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-feed-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- animals -->
          <validation-provider
            #default="validationContext"
            name="animals"
            rules="required"
          >
            <b-form-group :label="$t('animals')" label-for="animals">
              <v-select
                v-model="blankFeedData.animalid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="animals"
                class="w-100"
                :reduce="(val) => val.value"
                @input="onSelectProductionRation"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- productionRation -->
          <validation-provider
            #default="validationContext"
            name="productionRation"
            rules="required"
          >
            <b-form-group
              :label="$t('productionRation')"
              label-for="productionRation"
            >
              <v-select
                v-model="blankFeedData.productionrationid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productionRations"
                class="w-100"
                :reduce="(val) => val.value"
                @input="onSelectProductionRation"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- amount -->
          <validation-provider
            #default="validationContext"
            name="amount"
            rules="required"
          >
            <b-form-group :label="$t('amountYogun')" label-for="amount">
              <b-form-input
                id="aamountkaba"
                v-model="blankFeedData.amount"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="text"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- amountKaba -->
          <validation-provider
            #default="validationContext"
            name="amountKaba"
            rules="required"
          >
            <b-form-group :label="$t('amountKaba')" label-for="amountKaba">
              <b-form-input
                id="amountKaba"
                v-model="blankFeedData.amountkaba"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="text"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, computed, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import feed from "@/store/app/feed/feed";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewFeedSidebarActive",
    event: "update:is-add-new-feed-sidebar-active",
  },
  props: {
    modalRef: {
      type: Object,
      required: false,
      default: null,
    },
    isAddNewFeedSidebarActive: {
      type: Boolean,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
    animalSources: {
      type: Array,
      required: true,
    },
    animalBreeds: {
      type: Array,
      required: true,
    },
    animalStatus: {
      type: Array,
      required: true,
    },

    genderOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  async mounted() {
    await this.fetchProductionRations();
    await this.fetchAnimals();
  },
  async created() {
    // var { data } = await this.$http.get("Feeds/GetBreeds");
    // data.map((x) =>
    //   this.feedtypeOptions.push({ label: x.breedname, value: x.breedid })
    // );
  },
  methods: {
    async onSelectProductionRation(val) {
      if (val == null) return;
      const selectedRation = await store.dispatch(
        "rationsModule/fetchProductionRation",
        {
          id: val,
          tur: "11",
          plantid: this.$store.state.app.selectedPlantId,
        }
      );
      this.blankFeedData.amount = selectedRation.amountkesif;
      this.blankFeedData.amountkaba = selectedRation.amountkaba;
      this.blankFeedData.cost = selectedRation.pricekesif;
      this.blankFeedData.costkaba = selectedRation.pricekaba;
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const blankFeedData1 = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: "",
      productionrationid: "",
      amount: "",
      amountkaba: "",
      addusername: getUserData().name + " " + getUserData().surname,
    };
    let feedGroups = ref([]);
    let barns = ref([]);
    let productionRations = ref([]);
    let animals = ref([]);

    const fetchAnimals = async () => {
      let animalList = [];
      animalList = await store.dispatch(
        "feedsModule/fetchAnimalForAnimalFeeds",
        {
          plantid: store.state.app.selectedPlantId,
        }
      );
      animals.value = animalList.map((animal) => {
        return {
          value: animal.id,
          label: animal.earingno,
        };
      });
    };
    const fetchProductionRations = async () => {
      let productionRationList = [];
      productionRationList = await store.dispatch(
        "rationsModule/fetchProductionRations",
        {
          tur: "",
          plantid: store.state.app.selectedPlantId,
        }
      );
      productionRations.value = productionRationList.map((ration) => {
        return {
          value: ration.id,
          label: ration.rasyonadi,
          tip: ration.tip,
        };
      });
    };
    const blankFeedData = ref(JSON.parse(JSON.stringify(blankFeedData1)));
    const resetblankFeedData = () => {
      blankFeedData.value = JSON.parse(JSON.stringify(blankFeedData1));
    };

    const onSubmit = (feedData) => {
      store.dispatch("feedsModule/addFeed", feedData).then((res) => {
        fetchAnimals();
        fetchProductionRations();
        if (res.data.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("feedAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.data.statusdata == "0") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("feedAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-add-new-feed-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("feedNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };
    var feedsEar = computed(() => [
      { label: "Yok", value: -1 },
      ...store.state.feedsModule.feedsEar,
    ]);
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankFeedData);
    return {
      blankFeedData,
      onSubmit,
      barns,
      refFormObserver,
      getValidationState,
      resetForm,
      feedsEar,
      fetchAnimals,
      fetchProductionRations,
      animals,
      productionRations,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-feed-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
