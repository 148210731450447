<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" lg="4" xl="4" class="mb-md-0 mb-2 col-xxl-2">
          <label>{{ $t("animalType") }}</label>
          <v-select
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeFilter"
            :options="typeOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:typeFilter', val)"
          /> </b-col
        ><b-col cols="12" md="6" lg="4" xl="4" class="mb-md-0 mb-2 col-xxl-2">
          <label>{{ $t("genderName") }}</label>
          <v-select
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="genderFilter"
            :options="genderOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:genderFilter', val)"
          /> </b-col
        ><b-col cols="12" md="6" lg="4" xl="4" class="mb-md-0 mb-2 col-xxl-2">
          <label>{{ $t("animalStatus") }}</label>
          <v-select
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sourceFilter"
            :options="sourceOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:sourceFilter', val)"
          /> </b-col
        ><b-col cols="12" md="6" lg="4" xl="4" class="mb-md-0 mb-2 col-xxl-2">
          <label>{{ $t("statusName") }}</label>
          <v-select
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          /> </b-col
        ><b-col cols="12" md="6" lg="4" xl="4" class="mb-md-0 mb-2 col-xxl-2">
          <label>{{ $t("groupName") }}</label>
          <v-select
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="groupFilter"
            :options="groupOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:groupFilter', val)"
          /> </b-col
        ><b-col cols="12" md="6" lg="4" xl="4" class="mb-md-0 mb-2 col-xxl-2">
          <label>{{ $t("barnName") }}</label>
          <v-select
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="barnFilter"
            :options="barnOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:barnFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    typeFilter: {
      type: Number,
      default: null,
    },

    typeOptions: {
      type: Array,
      required: true,
    },
    genderFilter: {
      type: Number,
      default: null,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    sourceFilter: {
      type: Number,
      default: null,
    },
    sourceOptions: {
      type: Array,
      required: true,
    },
    statusFilter: {
      type: Number,
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    groupFilter: {
      type: Number,
      default: null,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
    barnFilter: {
      type: Number,
      default: null,
    },
    barnOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
