import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default function useFeedList() {
  // Use toast
  const toast = useToast();

  const refFeedListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { label: VueI18n.t("earingno"), key: "earingno", sortable: true },
    { key: "amount", label: VueI18n.t("amountYogun"), sortable: true },
    {
      key: "amountkaba",
      label: VueI18n.t("amountKaba"),
      sortable: true,
    },
    {
      key: "feedstartdate",
      label: VueI18n.t("feedStartDate"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val)
          .locale(`${VueI18n.locale}`)
          .format("DD MMMM YYYY dddd HH:mm")}`,
    },

    // { label: VueI18n.t("genderName"), key: "gendername", sortable: false },
    // { label: VueI18n.t("statusName"), key: "statusname", sortable: false },
    // { label: VueI18n.t("groupName"), key: "groupName", sortable: false },
    // { label: VueI18n.t("rfid"), key: "rfidno", sortable: false },
    // { label: VueI18n.t("birthdate"), key: "birthdate", sortable: false },
    // { key: "feedMilking", label: VueI18n.t("feedMilking") },
    // { key: "feedFeeds", label: VueI18n.t("feedFeeds") },
    { key: "edit", label: VueI18n.t("edit") },
    // { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);
  const genderFilter = ref(null);
  const sourceFilter = ref(null);
  const statusFilter = ref(null);
  const groupFilter = ref(null);
  const barnFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refFeedListTable.value
      ? refFeedListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFeeds.value,
    };
  });

  const totalFeeds = computed(() => {
    try {
      return feeds.value[0] ? feeds.value[0].totalFeedNumber ?? 0 : 0;
    } catch (error) {
      return 0;
    }
  });

  const feeds = computed(() => {
    try {
      return store.state.feedsModule.feeds.sort((a, b) => {
        if (isSortDirDesc.value) {
          return b[sortBy.value] > a[sortBy.value] ? 1 : -1;
        } else {
          return a[sortBy.value] > b[sortBy.value] ? 1 : -1;
        }
      });
    } catch (error) {
      return [];
    }
  });

  const refetchData = () => {
    fetchFeeds();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      typeFilter,
      genderFilter,
      statusFilter,
      groupFilter,
      sourceFilter,
      barnFilter,
    ],
    () => {
      store.commit("feedsModule/SET_CURRENT_PAGE", currentPage.value);
      store.commit("feedsModule/SET_PER_PAGE", perPage.value);

      refetchData();
    }
  );

  const deleteFeed = (ctx) => {
    store
      .dispatch("feedsModule/deleteFeed", { id: ctx })
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: "Feed deleted",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error delete feed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const fetchFeeds = async (ctx, callback) => {
    var feeds = await store
      .dispatch("feedsModule/fetchFeeds", {
        q: searchQuery.value ?? "",
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
        gender: genderFilter.value,
        status: statusFilter.value,
        sourceid: sourceFilter.value,
        groupid: groupFilter.value,
        plantid: store.state.app.selectedPlantId,
        padokid: barnFilter.value,
      })

      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("feeds") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return feeds;
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchFeeds,
    tableColumns,
    perPage,
    currentPage,
    totalFeeds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFeedListTable,
    deleteFeed,
    feeds,

    refetchData,

    // Extra Filters
    sourceFilter,
    typeFilter,
    genderFilter,
    statusFilter,
    groupFilter,
    barnFilter,
  };
}
