<template>
  <div>
    <feed-list-add-new
      :is-add-new-feed-sidebar-active.sync="isAddNewFeedSidebarActive"
      :animal-types="typeOptions"
      :animal-sources="sourceOptions"
      :animal-breeds="breedOptions"
      :gender-options="genderOptions"
      :animal-status="statusOptions"
      :animal-groups="groupOptions"
      @refetch-group="refetchGroup"
      @refetch-barns="refetchBarn"
      @refetch-data="refetchData"
    />
    <feed-list-edit
      :key="JSON.stringify(selectedFeed)"
      :is-add-new-feed-sidebar-active.sync="isEditFeedSidebarActive"
      :animal-types="typeOptions"
      :animal-sources="sourceOptions"
      :animal-breeds="breedOptions"
      :gender-options="genderOptions"
      :animal-status="statusOptions"
      :animal-groups="groupOptions"
      :selected-feed.sync="selectedFeed"
      @refetch-group="refetchGroup"
      @refetch-data="refetchData"
    />

    <feed-list-filters
      :type-filter.sync="typeFilter"
      :type-options="typeOptions"
      :gender-filter.sync="genderFilter"
      :gender-options="genderOptions"
      :source-filter.sync="sourceFilter"
      :source-options="sourceOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :group-filter.sync="groupFilter"
      :group-options="groupOptions"
      :barn-filter.sync="barnFilter"
      :barn-options="barnOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewFeedSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t("addFeed") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refFeedListTable"
        class="position-relative overflow-auto"
        :items="feeds"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        select-mode="single"
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
        tbody-tr-class="rowClass"
      >
        <template #cell(earingno)="data">
          {{ data.item.earingno }}
        </template>
        <template #cell(delete)="data">
          <b-link class="d-flex" @click="deleteFeed(data.item.id)">
            <feather-icon icon="TrashIcon" />
            <small class="align-middle ml-50 text-dark">
              {{ $t("delete") }}
            </small>
          </b-link>
        </template>
        <template #cell(edit)="data">
          <b-link class="d-flex" @click="editFeed(data.item)">
            <feather-icon icon="EditIcon" />
            <small class="align-middle ml-50 text-dark">
              {{ $t("edit") }}
            </small>
          </b-link>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFeeds"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            class="mt-2 d-flex align-items-end justify-content-end justify-content-sm-start"
          >
            <span class="text-muted text-right">
              {{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}
            </span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useFeedList from "./useFeedList";
import FeedListAddNew from "./FeedListAddNew.vue";
import FeedListEdit from "./FeedListEdit.vue";
import FeedListFilters from "./FeedListFilters.vue";
import vSelect from "vue-select";

export default {
  components: {
    FeedListAddNew,
    FeedListEdit,
    FeedListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,

    vSelect,
  },
  data() {
    return {
      detailsRow: null,
      selectedFeed: {},
      typeOptions: [],
      sourceOptions: [],
      breedOptions: [],
      statusOptions: [],
      groupOptions: [],
      barnOptions: [],
      detailInfoHeight: 72,
      genderOptions: [
        { label: this.$t("none"), value: null },
        { label: "Dişi", value: 1 },
        { label: "Erkek", value: 2 },
      ],
    };
  },
  computed: {},
  watch: {
    selectedFeed: {
      async handler(newVal) {
        this.$store.commit("feedsModule/SET_FEED", newVal);
        const { href } = this.$router.resolve({
          ...this.$router.currentRoute,
          query: { id: newVal.id },
        });
        window.history.pushState({}, null, href);
      },
      deep: true,
    },
  },
  mounted() {},
  async created() {
    this.$root.$on("refetch-data", this.fetchFeeds);

    this.typeOptions = [{ label: this.$t("none"), value: null }];
    this.sourceOptions = [{ label: this.$t("none"), value: null }];
    this.breedOptions = [{ label: this.$t("none"), value: null }];
    this.statusOptions = [{ label: this.$t("none"), value: null }];
    var feedProperties = {};

    const [types, sources, breeds, statuses] = await Promise.allSettled([
      this.$http.get("/Animals/GetAnimalTypes"),
      this.$http.get("/Animals/GetAnimalSources"),
      this.$http.get("/Animals/GetAnimalBreeds"),
      this.$store.dispatch("definitionsModule/fetchStatuses"),
    ]);
    if (types.status === "fulfilled")
      types.value.data.map((x) =>
        this.typeOptions.push({ label: x.name, value: x.id })
      );
    if (sources.status === "fulfilled")
      sources.value.data.map((x) =>
        this.sourceOptions.push({ label: x.name, value: x.id })
      );
    if (breeds.status === "fulfilled")
      breeds.value.data.map((x) =>
        this.breedOptions.push({ label: x.name, value: x.id })
      );

    if (statuses.status === "fulfilled")
      statuses.value.map((x) =>
        this.statusOptions.push({ label: x.name, value: x.id })
      );
    feedProperties = {
      type: this.typeOptions,
      source: this.sourceOptions,
      breed: this.breedOptions,
      status: this.statusOptions,
    };
    this.$store.commit("animalsModule/SET_ANIMALPROPERTIES", feedProperties);
    this.refetchGroup();
    this.refetchBarn();
  },
  methods: {
    async refetchGroup() {
      this.groupOptions = [];
      var data = await this.$store.dispatch("animalsModule/fetchGroups");
      this.groupOptions = [
        { label: this.$t("none"), value: null },
        ...data.map((x) => ({
          label: x.groupname,
          value: x.id,
        })),
      ];
    },
    async refetchBarn() {
      this.barnOptions = [];
      var data = await this.$store.dispatch(
        "definitionsModule/fetchBarns",
        this.$store.state.app.selectedPlantId
      );
      this.barnOptions = [
        { label: this.$t("none"), value: null },
        ...data.map((x) => ({
          label: x.barnname,
          value: x.id,
        })),
      ];
    },
    editFeed(feed) {
      this.selectedFeed = { ...feed };
      this.isEditFeedSidebarActive = true;
    },
    addFeedMilking(feed) {
      this.selectedFeed = { ...feed };
      this.isAddNewFeedMilkingSidebarActive = true;
    },
    editFeedMilking(feed) {
      this.selectedFeed = { ...feed };
      this.isEditFeedMilkingSidebarActive = true;
    },
    async selectFeed(feed) {
      this.selectedFeed = { ...feed };
    },
    async fetchFeedExams(id) {
      let exams = await this.$store.dispatch("feedsModule/fetchFeedExams", id);
      this.selectedFeed.exams = [];
      this.$forceUpdate();
      // exams = exams.splice(0, 5);
      this.selectedFeed.exams = exams;
      this.$set(this.selectedFeed, "exams", exams);
      this.selectedFeed = this.selectedFeed;
      this.$forceUpdate();
    },
  },
  setup() {
    const isAddNewFeedSidebarActive = ref(false);
    const isEditFeedSidebarActive = ref(false);
    const isAddNewFeedMilkingSidebarActive = ref(false);
    const isEditFeedMilkingSidebarActive = ref(false);
    const {
      fetchFeeds,
      tableColumns,
      perPage,
      currentPage,
      totalFeeds,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFeedListTable,
      refetchData,
      deleteFeed,
      feeds,
      // UI
      perPageOptions,

      // Extra Filters
      sourceFilter,
      typeFilter,
      genderFilter,
      statusFilter,
      groupFilter,
      barnFilter,
    } = useFeedList();
    fetchFeeds();

    return {
      // Sidebar
      isAddNewFeedSidebarActive,
      isEditFeedSidebarActive,
      isAddNewFeedMilkingSidebarActive,
      isEditFeedMilkingSidebarActive,
      fetchFeeds,
      tableColumns,
      perPage,
      currentPage,
      totalFeeds,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFeedListTable,
      refetchData,
      perPageOptions,
      deleteFeed,
      feeds,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      sourceFilter,
      typeFilter,
      genderFilter,
      statusFilter,
      groupFilter,
      barnFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
.rowClass {
  cursor: pointer !important;
}
.table tbody tr td .card {
  cursor: default;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 216);
}
</style>
